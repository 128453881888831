body {
	background-color: white;
}
/* Navbar styles */
.navbar-custom {
	box-shadow: 0 1px 5px #18181b;
	padding-left: 50px !important;
	padding-right: 50px !important;
	background: linear-gradient(
		to bottom,
		rgba(230, 219, 219, 0.7),
		rgba(230, 219, 219, 0.7)
	) !important;
	backdrop-filter: blur(1.5rem);
	/* border-bottom: solid 1px #004aad; */
	@media (max-width: 960px) {
		padding: 20px 15px !important;
	}
}
.navbar-link {
	color: #004aad;
	font-size: 18px;
	text-decoration: none;
}
.navbar-link:hover {
	color: #004aad !important;
	transition: 0.3s;
	border-bottom: solid #004aad 3px;
}
.navbar-nav .nav-link.active {
	color: #004aad;
	border-bottom: solid #004aad 3px;
}
.side-menu {
	display: flex;
	align-items: center;
}
.contact-button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 18px;
	padding: 10px;
	background-color: #004aad;
	border: solid 2px #004aad;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
}
.contact-button:hover {
	color: #004aad;
	background-color: #ffffff95 !important;
	border: #004aad 2px solid;
	transition: 0.5s;
}
.contact-button:active {
	color: white;
}

@media screen and (max-width: 960px) {
	.navbar-light .navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 74, 173, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	}
	/* burger menu border styles */
	.navbar-light .navbar-toggler {
		border: 1px solid #004aad;
	}
	.side-menu {
		background-color: #e6dbdb;
	}
	.navbar-link {
		font-size: 1.5rem;
		margin: 0 0 20px 0 !important;
	}
	.carousel-slide {
		width: 100%;
	}
}
@media screen and (max-width: 500px) {
	.burger-button {
		height: 30px;
		width: 40px;
	}
	.navbar-toggler-icon {
		position: relative;
		width: 20px;
		height: 40px;
		margin: -9px 0 0 -2px;
	}
}
